
$( document ).ready(function() {
  console.log("msg from application.js",$(".count-down-container").length);

  $(".count-down-container").each(function(index) {
    let el = $(this);
    console.log( index + ": " + $( this ).text() );
    console.log(el);
    countDown(el.attr('id'), el.data("ended-at"));
  });
});

function cuteNumber(number) {
  return(number < 10 ? '0' + number : number);
}

function countDown(el, date) {
  var countDownDate = new Date(date).getTime();

  // Update the count down every 1 second
  var x = setInterval(function() {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    // document.getElementById(el).innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    document.getElementById(el + "-days").innerText = cuteNumber(days);
    document.getElementById(el + "-hours").innerText = cuteNumber(hours);
    document.getElementById(el + "-minutes").innerText = cuteNumber(minutes);
    document.getElementById(el + "-seconds").innerText = cuteNumber(seconds);

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById(el).innerHTML = "EXPIRED";
    }
  }, 1000);
};
